import { default as _91slug_931xO3VJXapCMeta } from "/vercel/path0/pages/@[user]/list/[slug].vue?macro=true";
import { default as _91section_934cVNZGti9iMeta } from "/vercel/path0/pages/account/[section].vue?macro=true";
import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as cloudD76X6CbedWMeta } from "/vercel/path0/pages/account/sections/cloud.vue?macro=true";
import { default as databasev4JLRFROOMMeta } from "/vercel/path0/pages/account/sections/database.vue?macro=true";
import { default as historyjZrb2Eu4I0Meta } from "/vercel/path0/pages/account/sections/history.vue?macro=true";
import { default as linkedHOxJRxaleSMeta } from "/vercel/path0/pages/account/sections/linked.vue?macro=true";
import { default as listsENo0CZiHI8Meta } from "/vercel/path0/pages/account/sections/lists.vue?macro=true";
import { default as logswgGUW3AfDjMeta } from "/vercel/path0/pages/account/sections/logs.vue?macro=true";
import { default as me6yg1j9xS7cMeta } from "/vercel/path0/pages/account/sections/me.vue?macro=true";
import { default as preferencesKbp25BOzneMeta } from "/vercel/path0/pages/account/sections/preferences.vue?macro=true";
import { default as states3ur0GidQeMMeta } from "/vercel/path0/pages/account/sections/states.vue?macro=true";
import { default as updateAlw39xpa5eMeta } from "/vercel/path0/pages/account/update.vue?macro=true";
import { default as authI5JGIXywJyMeta } from "/vercel/path0/pages/auth.vue?macro=true";
import { default as changelog_45wip9cuqq0eKNiMeta } from "/vercel/path0/pages/changelog-wip.vue?macro=true";
import { default as changelogsaUcnbuokEMeta } from "/vercel/path0/pages/changelog.vue?macro=true";
import { default as dashboardHdtnClvoGAMeta } from "/vercel/path0/pages/dashboard.vue?macro=true";
import { default as _91_46_46_46slug_93UUgP4euXUtMeta } from "/vercel/path0/pages/docs/[...slug].vue?macro=true";
import { default as _91slug_93UW1I6cRFThMeta } from "/vercel/path0/pages/game/[slug].vue?macro=true";
import { default as _91_46_46_46slug_9328RE0nnjmzMeta } from "/vercel/path0/pages/games/[...slug].vue?macro=true";
import { default as index3mhzZpolB9Meta } from "/vercel/path0/pages/genres/index.vue?macro=true";
import { default as _91platform_93SMSmS7s8PbMeta } from "/vercel/path0/pages/import/[platform].vue?macro=true";
import { default as indexVRFGBraYCHMeta } from "/vercel/path0/pages/import/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as index83OYD4s8wXMeta } from "/vercel/path0/pages/journal/index.vue?macro=true";
import { default as _91_46_46_46slug_93UoUrkZwgn9Meta } from "/vercel/path0/pages/library/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93zOyJHppNKEMeta } from "/vercel/path0/pages/my/list/[...slug].vue?macro=true";
import { default as sitemapmtRscZaA8vMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
import { default as supabasePo1ao59FgEMeta } from "/vercel/path0/pages/supabase.vue?macro=true";
import { default as testlK5QpQxp8eMeta } from "/vercel/path0/pages/test.vue?macro=true";
import { default as theloginW0uUvaFR3kMeta } from "/vercel/path0/pages/thelogin.vue?macro=true";
import { default as welcomelxppa1y2l8Meta } from "/vercel/path0/pages/welcome.vue?macro=true";
export default [
  {
    name: "@user-list-slug",
    path: "/@:user()/list/:slug()",
    component: () => import("/vercel/path0/pages/@[user]/list/[slug].vue")
  },
  {
    name: "account-section",
    path: "/account/:section()",
    component: () => import("/vercel/path0/pages/account/[section].vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account-sections-cloud",
    path: "/account/sections/cloud",
    component: () => import("/vercel/path0/pages/account/sections/cloud.vue")
  },
  {
    name: "account-sections-database",
    path: "/account/sections/database",
    component: () => import("/vercel/path0/pages/account/sections/database.vue")
  },
  {
    name: "account-sections-history",
    path: "/account/sections/history",
    component: () => import("/vercel/path0/pages/account/sections/history.vue")
  },
  {
    name: "account-sections-linked",
    path: "/account/sections/linked",
    component: () => import("/vercel/path0/pages/account/sections/linked.vue")
  },
  {
    name: "account-sections-lists",
    path: "/account/sections/lists",
    component: () => import("/vercel/path0/pages/account/sections/lists.vue")
  },
  {
    name: "account-sections-logs",
    path: "/account/sections/logs",
    component: () => import("/vercel/path0/pages/account/sections/logs.vue")
  },
  {
    name: "account-sections-me",
    path: "/account/sections/me",
    component: () => import("/vercel/path0/pages/account/sections/me.vue")
  },
  {
    name: "account-sections-preferences",
    path: "/account/sections/preferences",
    component: () => import("/vercel/path0/pages/account/sections/preferences.vue")
  },
  {
    name: "account-sections-states",
    path: "/account/sections/states",
    component: () => import("/vercel/path0/pages/account/sections/states.vue")
  },
  {
    name: "account-update",
    path: "/account/update",
    meta: updateAlw39xpa5eMeta || {},
    component: () => import("/vercel/path0/pages/account/update.vue")
  },
  {
    name: "auth",
    path: "/auth",
    component: () => import("/vercel/path0/pages/auth.vue")
  },
  {
    name: "changelog-wip",
    path: "/changelog-wip",
    component: () => import("/vercel/path0/pages/changelog-wip.vue")
  },
  {
    name: "changelog",
    path: "/changelog",
    component: () => import("/vercel/path0/pages/changelog.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/vercel/path0/pages/dashboard.vue")
  },
  {
    name: "docs-slug",
    path: "/docs/:slug(.*)*",
    component: () => import("/vercel/path0/pages/docs/[...slug].vue")
  },
  {
    name: "game-slug",
    path: "/game/:slug()",
    component: () => import("/vercel/path0/pages/game/[slug].vue")
  },
  {
    name: "games-slug",
    path: "/games/:slug(.*)*",
    component: () => import("/vercel/path0/pages/games/[...slug].vue")
  },
  {
    name: "genres",
    path: "/genres",
    component: () => import("/vercel/path0/pages/genres/index.vue")
  },
  {
    name: "import-platform",
    path: "/import/:platform()",
    meta: _91platform_93SMSmS7s8PbMeta || {},
    component: () => import("/vercel/path0/pages/import/[platform].vue")
  },
  {
    name: "import",
    path: "/import",
    meta: indexVRFGBraYCHMeta || {},
    component: () => import("/vercel/path0/pages/import/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "journal",
    path: "/journal",
    component: () => import("/vercel/path0/pages/journal/index.vue")
  },
  {
    name: "library-slug",
    path: "/library/:slug(.*)*",
    component: () => import("/vercel/path0/pages/library/[...slug].vue")
  },
  {
    name: "my-list-slug",
    path: "/my/list/:slug(.*)*",
    component: () => import("/vercel/path0/pages/my/list/[...slug].vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "supabase",
    path: "/supabase",
    component: () => import("/vercel/path0/pages/supabase.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/pages/test.vue")
  },
  {
    name: "thelogin",
    path: "/thelogin",
    component: () => import("/vercel/path0/pages/thelogin.vue")
  },
  {
    name: "welcome",
    path: "/welcome",
    meta: welcomelxppa1y2l8Meta || {},
    component: () => import("/vercel/path0/pages/welcome.vue")
  }
]